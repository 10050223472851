import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { Container } from '@material-ui/core';
import Button from 'components/Buttons/Button';
import { Prompt, StyledLink } from 'styles';
import { breakPoints } from 'styles/variables';

const InnerContent = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${breakPoints.small}) {
    padding: 0 20px;
    margin-left: 20px;
    text-align: justify;
  }

  a {
    text-decoration: underline;
  }

  img {
    margin-bottom: 2em;
    height: 10em;
    width: auto;
    min-height: 220px;

    @media screen and (min-width: ${breakPoints.large}) {
      height: 20em;
      min-height: 285px;
      margin-bottom: 3em;
    }
  }
`;

const StyledContainer = styled(Container)`
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: ${props => (props.smFont ? '16px' : '18px')};
    line-height: 1.56;
    letter-spacing: 0.45px;
    font-weight: normal;
    padding: 0 10px;

    @media only screen and (min-width: ${breakPoints.large}) {
      padding: 0;
      width: 675px;
    }
  }
`;

const StyledHeader = styled(Prompt)`
  margin: 40px auto;
  max-width: 80%;

  @media screen and (min-width: ${breakPoints.large}) {
    max-width: none;
  }
`;

const StyledMessage = styled.p`
  margin: 0 auto;
  text-align: center;
  line-height: 1.43;
  letter-spacing: 0.35px;

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 30px;
    `}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${props => (props.paddingBottom ? '30px' : '0')};
`;

function DeclinedDoNotQuote() {
  const doNotQuote = useSelector(state => state.app?.doNotQuote);

  const { header, message, messageDetail, DNQ: dnq } = doNotQuote;

  const membershipIneligible = dnq === 'USAA_MEMBERSHIP_INELIGIBLE';
  const stateWithdrawal = dnq === 'STATE_WITHDRAWAL';
  const stateEligible = dnq === 'STATE_ELIGIBLE';
  const underwritingIneligible =
    dnq === 'DRIVER_BI_POINTS_EXCEEDED' ||
    dnq === 'POLICY_BI_POINTS_EXCEEDED' ||
    dnq === 'LIC_STATUS_NOT_VALID' ||
    dnq === 'NO_MVR_REPORT';

  const dnqHeader = membershipIneligible
    ? 'Thanks for requesting an auto insurance quote. We want to help.'
    : header;

  let messageDetails = null;

  if (underwritingIneligible || stateWithdrawal || stateEligible) {
    messageDetails = messageDetail;
  } else if (membershipIneligible) {
    messageDetails = `If you're ineligible for a USAA membership, we can help you get the auto and vehicle coverage and savings you need with products from the USAA Insurance Agency.`;
  } else {
    messageDetails = `We're unable to provide you a Noblr quote at this time. Please contact us for further assistance.`;
  }

  return (
    <>
      <StyledHeader>{dnqHeader}</StyledHeader>
      <StyledContainer>
        {message && !membershipIneligible && (
          <StyledMessage
            marginBottom
            dangerouslySetInnerHTML={{
              __html: message
            }}
          />
        )}
        {dnq === 'STATE_ELIGIBLE' && (
          <InnerContent>
            <img
              src={`${process.env.REACT_APP_NOBLR_CDN}/images/csr-avatar.webp`}
              alt="Illustration of a Customer Support Representative"
            />
          </InnerContent>
        )}
        {message && membershipIneligible && (
          <StyledMessage marginBottom>
            {`We are unable to validate your USAA membership. Please
            verify your USAA membership at `}
            <StyledLink
              href="https://www.usaa.com/join"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="opens a new window to usaa.com/join"
            >
              www.usaa.com/join
            </StyledLink>
            {` and try again.`}
          </StyledMessage>
        )}
        <Wrapper>
          {(underwritingIneligible || stateWithdrawal || stateEligible) &&
          messageDetails ? (
            <StyledMessage
              dangerouslySetInnerHTML={{
                __html: messageDetails
              }}
            />
          ) : (
            <Wrapper>
              <StyledMessage>{messageDetails}</StyledMessage>
            </Wrapper>
          )}
        </Wrapper>
        {!stateEligible ? (
          <Wrapper paddingBottom>
            <Button
              as="a"
              primary
              extraLarge
              className="phone-number-button"
              type="button"
              href="tel:+8557644715"
            >
              CALL USAA AT 855-764-4715
            </Button>
          </Wrapper>
        ) : null}
      </StyledContainer>
    </>
  );
}

export default DeclinedDoNotQuote;
